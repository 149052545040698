import React, { useState } from "react"
import {
  ContentContainer,
  PostCard,
  Tag,
  Category,
  BlogContent,
  Seo,
  // Pagination,
} from "../components"
import { graphql, Link } from "gatsby"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

const AllPost = ({ data }) => {
  const allPosts = data.postsRemark.edges
  const postCount = data.postsRemark.totalCount
  const allTags = data.tagsGroup.group
  const allCategories = data.categoryGroup.group

  // PAGINATION PROPS

  const [paginationSkip, setPaginationSkip] = useState(0)
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1)

  const maxPaginationPage = Math.ceil(data.postsRemark.totalCount / 4)
  const isFirst = currentPaginationPage === 1
  const isLast = currentPaginationPage === maxPaginationPage

  const handlePaginationChange = i => {
    if (i >= 0 && i <= postCount) {
      setCurrentPaginationPage(i)
      // setPaginationSkip(i)
    }
  }

  // SEARCH PROPS

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value
    const posts = allPosts || []

    const filteredData = posts.filter(post => {
      const { description, title, tags } = post.node.frontmatter
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags && tags.join("").toLowerCase().includes(query.toLowerCase()))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <ContentContainer>
      <Seo
        title="Blog Posts"
        // image={seoImage}
        description="Page for guides, tutorial, or just notes."
      />
      <BlogContent>
        <div className="container">
          <section className="blog-content">
            {posts
              .slice((currentPaginationPage - 1) * 4, currentPaginationPage * 4)
              .map(({ node }, index) => (
                <PostCard
                  key={index}
                  to={node.frontmatter.slug}
                  description={node.frontmatter.description}
                  title={node.frontmatter.title}
                  date={node.frontmatter.date}
                />
              ))}
            {/* <Pagination
                isFirst={isFirst}
                isLast={isLast}
                prevPage={prevPage}
                nextPage={nextPage}
              /> */}

            <div className="pagination">
              {!isFirst && (
                <button
                  className="button"
                  // href={prevPage < 2 ? "/posts" : `/posts/${prevPage}`}
                  onClick={e =>
                    handlePaginationChange(currentPaginationPage - 1)
                  }
                >
                  <BsChevronLeft />
                  {/* <Link
                      to={prevPage < 2 ? "/posts" : `/posts/${prevPage}`}
                      rel="prev"
                    ></Link> */}
                </button>
              )}

              {Array.from({ length: maxPaginationPage }, (_, i) => (
                <button
                  className="button"
                  active={currentPaginationPage == i + 1 ? true : false}
                  onClick={e => handlePaginationChange(i + 1)}
                  // href={i === 0 ? "/posts" : `/posts/${i + 1}`}
                >
                  {/* <Link
                      key={`pagination-number${i + 1}`}
                      to={i === 0 ? "/posts" : `/posts/${i + 1}`}
                    >
                    </Link> */}
                  {i + 1}
                </button>
              ))}

              {!isLast && (
                <button
                  className="button"
                  // href={`/posts/${nextPage}`}
                  onClick={e =>
                    handlePaginationChange(currentPaginationPage + 1)
                  }
                >
                  <BsChevronRight />
                  {/* <Link to={`/posts/${nextPage}`} rel="next">
                      →
                    </Link> */}
                </button>
              )}
            </div>
          </section>

          <section className="blog-sidebar">
            <div className="side-container">
              <form className="search-form">
                <input
                  className="search-field"
                  type="search"
                  placeholder="Search Posts"
                  aria-label="Search Posts"
                  onChange={handleInputChange}
                />
              </form>
            </div>

            {/* <Tags /> */}
            <div className="side-container">
              <h1>Tags</h1>
              {allTags.map(node => (
                <Tag href={`/tags/${node.fieldValue}/`}>{node.fieldValue}</Tag>
              ))}
            </div>

            {/* <Categories /> */}
            <div className="side-container">
              <h1>Categories</h1>
              {allCategories.map(node => (
                <Category
                  href={`/categories/${node.fieldValue}/`}
                  count={node.totalCount}
                >
                  {node.fieldValue}
                </Category>
              ))}
            </div>
          </section>
        </div>
      </BlogContent>
    </ContentContainer>
  )
}

export default AllPost

export const pageQuery = graphql`
  query {
    postsRemark: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM YYYY")
            tags
            slug
          }
        }
      }
      totalCount
    }
    tagsGroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    categoryGroup: allMdx(limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`

// export const pageQuery = graphql`
//   query($skip: Int!, $limit: Int!) {
//     postsRemark: allMdx(
//       sort: { fields: frontmatter___date, order: DESC }
//       skip: $skip
//       limit: $limit
//     ) {
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             description
//             image {
//               childImageSharp {
//                 fixed(height: 155, quality: 100) {
//                   ...GatsbyImageSharpFixed
//                 }
//               }
//             }
//             date(formatString: "MMMM YYYY")
//             tags
//             slug
//           }
//         }
//       }
//     }
//     tagsGroup: allMdx(limit: 2000) {
//       group(field: frontmatter___tags) {
//         fieldValue
//       }
//     }
//     categoryGroup: allMdx(limit: 2000) {
//       group(field: frontmatter___category) {
//         fieldValue
//         totalCount
//       }
//     }
//   }
// `
